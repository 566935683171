
/**
 * takes in m3u8 main HLS URL and returns first available EXT-X-PROGRAM-DATE-TIME
 * @param m3u8Url
 */
export async function determineProgramDateTime(m3u8Url: string): Promise<Date | null> {

  try {
    let m3u8Response = await fetch(m3u8Url)
    let m3u8Lines = await m3u8Response.text()
    let urls = m3u8Lines.split("\n").filter(line => line.startsWith("https://"))

    let pdtPayloadResponse = await fetch(urls[0])
    let pdtPayloadLines = await pdtPayloadResponse.text()

    let pdtLine = pdtPayloadLines.split("\n").find(line => line.startsWith("#EXT-X-PROGRAM-DATE-TIME"))!!
    let pdt = pdtLine.replace("#EXT-X-PROGRAM-DATE-TIME:", "")
    return new Date(pdt)
  } catch (e) {
    return null
  }
}